/* stylelint-disable value-keyword-case */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@use './app/app.component-theme' as app;
@use './app/jobs/components/job-details/job-events/job-events.component-theme' as job-events;
@use './app/region/region-select/region-select.component-theme' as region-select;

@use './styles/page.component-theme' as page;
@use './styles/mat-dialog.component-theme' as mat-dialog;
@use './styles/mat-expansion-panel.component-theme' as mat-expansion-panel;
@use './styles/mat-form-field.component-theme' as mat-form-field;
@use './styles/mat-icon.component-theme' as mat-icon;
@use './styles/mat-tab.component-theme' as mat-tab;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sca-primary: mat.m2-define-palette(mat.$m2-light-blue-palette, 700);
$sca-accent: mat.m2-define-palette(mat.$m2-amber-palette, 900);

// The warn palette is optional (defaults to red).
$sca-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette, 700);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sca-dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $sca-primary,
      accent: $sca-accent,
      warn: $sca-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

$sca-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $sca-primary,
      accent: $sca-accent,
      warn: $sca-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@mixin component-themes($theme) {
  @include app.component-theme($theme);
  @include job-events.component-theme($theme);
  @include region-select.component-theme($theme);
  @include page.component-theme($theme);
  @include mat-dialog.component-theme($theme);
  @include mat-form-field.component-theme($theme);
}

@include mat.core-theme($sca-dark-theme);
@include mat.all-component-themes($sca-dark-theme);
@include component-themes($sca-dark-theme);

.light {
  @include mat.core-color($sca-light-theme);
  @include mat.all-component-colors($sca-light-theme);
  @include component-themes($sca-light-theme);
}
