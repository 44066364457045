@use '@angular/material' as mat;

@mixin component-theme($theme) {
  .region-select-menu button {
    color: #{mat.get-theme-color($theme, foreground, 'text')};

    &.selected {
      color: #fff;
      font-weight: 500;
      background-color: #{mat.get-theme-color($theme, primary, 'default')};
    }
  }
}
