@use '@angular/material' as mat;

@mixin component-theme($theme) {
  :root {
    --info-color: #{mat.get-theme-color($theme, primary)};
    --warning-color: #{mat.get-theme-color($theme, accent)};
    --error-color: #{mat.get-theme-color($theme, warn)};
    --highlight-color: #{mat.get-theme-color($theme, primary, 'darker')};
  }

  pre {
    padding: 0.5rem;
    background-color: #{mat.get-theme-color($theme, 'background', 'background')};
  }

  mat-sidenav {
    --mat-sidenav-container-background-color: #{mat.get-theme-color($theme, primary, 900)};
    --mat-sidenav-container-text-color: #fff;

    mat-nav-list {
      [mat-list-item] {
        --mdc-list-list-item-leading-icon-color: #fff;
        --mdc-list-list-item-hover-leading-icon-color: #fff;
        --mdc-list-list-item-label-text-color: #fff;
        --mdc-list-list-item-focus-label-text-color: #fff;
        --mdc-list-list-item-focus-state-layer-color: #fff;
        --mdc-list-list-item-focus-state-layer-opacity: 0;
        --mdc-list-list-item-hover-label-text-color: #fff;
        --mdc-list-list-item-hover-state-layer-color: #fff;

        &.active {
          --mdc-list-list-item-container-color: #{mat.get-theme-color($theme, primary, 'default')};
        }
      }
    }
  }
}
