@use 'sass:map';
@use '@angular/material' as mat;

@mixin component-theme($theme) {
  sda-job-events {
    mat-table {
      mat-row {
        background-color: inherit;
        cursor: pointer;

        &:hover mat-cell {
          background-color: #{mat.get-theme-color($theme, 'background', 'hover')};
        }

        &.active mat-cell {
          background-color: #{mat.get-theme-color($theme, 'background', 'focused-button')};
        }
      }
    }
  }
}
