/*!
 * Modus Icons v1.15.0 (https://modus-icons.trimble.com/)
 * Copyright 2023-2024 Trimble Inc.
 * Licensed under MIT (https://github.com/trimble-oss/modus-icons/blob/main/LICENSE.md)
 */

@font-face {
  font-family: "modus-icons";
  font-display: block;
  src: url("modus-icons.woff2?f0a624051587c95b0ca2011260057bf2") format("woff2"),
url("modus-icons.woff?f0a624051587c95b0ca2011260057bf2") format("woff");
}

.modus-icons {
  font-family: "modus-icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
