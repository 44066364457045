@use '@angular/material' as mat;

@mixin component-theme($theme) {
  [mat-dialog-title] {
    --mdc-dialog-subhead-color: #{mat.get-theme-color($theme, primary, 'default-contrast')};

    &.mat-mdc-dialog-title {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
    }
  }

  [mat-dialog-actions].mat-mdc-dialog-actions {
    padding: 1rem;
  }
}
