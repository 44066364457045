@use '@angular/material' as mat;

@mixin component-theme($theme) {
  //make form inputs a bit smaller
  @include mat.form-field-density(-1);

  mat-form-field {
    // override the default input label color
    --mdc-filled-text-field-focus-label-text-color: #{mat.get-theme-color(
        $theme,
        foreground,
        'text'
      )};

    // override form field icons to honor inline display
    .mat-mdc-form-field-icon-suffix {
      display: flex;
    }
  }
}
