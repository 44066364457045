@tailwind base;
@tailwind components;
@tailwind utilities;

@import './lib/modus-autocomplete/modus-autocomplete';
@import './lib/modus-buttons/modus-button-base';
@import './lib/modus-input/modus-input';
@import './lib/modus-select/modus-select';
@import './lib/modus-tooltip/modus-tooltip';
@import './lib/modus-form-field/modus-form-field';
@import './lib/modus-list/modus-list';
@import './lib/modus-menu/modus-menu';
