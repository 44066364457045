@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import '../../../libs/modus/src/modus.scss';

html,
body {
  height: 100%;
}
