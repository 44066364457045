@use '@angular/material' as mat;

@mixin component-theme($theme) {
  .page-section {
    background-color: #{mat.get-theme-color($theme, 'background', 'card')};
  }
  .page-section-header {
    border-bottom: 1px solid #{mat.get-theme-color($theme, foreground, 'divider')};
  }
}

.page-header {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.7rem;
}
.page-section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.page-section-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: -1rem;
  margin-bottom: 0.8rem;
  height: 3.6rem;
  font-weight: 400;
  font-size: 1rem;
}
.page-footer {
  display: flex;
  margin-top: 1rem;
}
